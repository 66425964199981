.sheet {}



.skin-classic {
   .sheet {
      padding: 30px;
      // background-color: #f1f1f1

      section {
         .section-title {
            background-color: #fff;
            height: 42px;
            margin-bottom: 1px;
            padding: 5px 15px;
            line-height: 32px; }

         .section-content {
            background-color: #fff;
            padding: 15px; } }

      section+section {
         margin-top: 30px; } } }

.isMobile.skin-classic {
      .sheet {
         padding: 10px; } }


.skin-modern {
   .sheet {
      background-color:  #ffffff;
      .bounded {
         position: relative;
         max-width: 1200px;
         width: calc(100% - 30px);
         margin: 0 auto;
         overflow: visible; }

      section {
         background-color: #f1f1f1;
         margin-bottom: 10px;

         .section-title {
            font-size: 22px;
            font-weight: 600;
            padding: 30px 0px 5px; }


         .section-content {
            padding: 40px 0px; } }

      section+section {
         margin-top: 10px; } } }
