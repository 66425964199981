#sheetTradeSectors {

   .section-content {
      .menu {
         padding: 10px 0px 5px;

         .item {
            color: #6f6f6f;
            border-bottom: 2px solid transparent;
            display: inline-block;
            padding-bottom: 2px;
            border-bottom: 2px solid transparent;
            font-weight: bold;
            opacity: 0.5;
            cursor: pointer; }

         .item.active {
            border-color: #6f6f6f;
            opacity: 1; }

         .item:not(:last-child) {
            margin-right: 20px; } }

      .sector {
         padding-top: 3px;

         span {
            display: block;
            font-size: 14px;
            padding-top: 12px;
            color: #6f6f6f; } } } }
