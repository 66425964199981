.filter-list.item-leaf{
   width: 100%;
}
.filter-list.item-leaf .text{
   flex-grow: 1;
   flex-shrink: 1;
}
.filter-list.item-leaf .checked{
   height: 20px;
   flex-basis: 30px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-right: 10px;
}
.filter-list.item-leaf.active .checked{
   background-image: url("/assets/icons/tick.svg");
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center right;
}
