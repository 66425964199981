.tag{
   width: calc(100% + 40px);
   font-size: 16px;
   color: #ffffff;
   margin: 4px -20px 0px;
   padding: 5px 20px;
   text-transform: none;
   line-height: 18px;
   display: flex;
   cursor: default;
}
.tag .text{
   flex-grow: 1;
   flex-shrink: 1;
}
.tag .text .value{
   font-size: 16px;
   color: #ffffff;
}
.tag .remove{
   flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 10px;
   height: 10px;
   /* display: none; */

   background-image: url("/assets/icons/close-1.svg");
   background-size: contain;
   background-repeat: no-repeat;
   background-position: top center;
}
.tag.active{
   background-color: #00000033;
}
.tag.active .remove{
   display: block;
}
