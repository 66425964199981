
#page > .header{
   flex-grow: 0;
   flex-shrink: 0;
   background-color: #fff;
   -webkit-box-shadow: 0px 3px 2px 1px rgba(0,0,0,0.1);
   -moz-box-shadow: 0px 3px 2px 1px rgba(0,0,0,0.1);
   box-shadow: 0px 2px 3px 1px rgba(0,0,0,0.1);

   padding: 10px 20px 0px;
   z-index: 2;
}
#page > .header .main{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   /* border: 1px solid red; */
}

#page > .header .main .left,
#page > .header .main .right{
   flex-basis: 50px;
   flex-grow: 0;
   flex-shrink: 0;
}
#page > .header .main .center{
   flex-grow: 0;
   flex-shrink: 1;
   text-align: center;
   /* transform: scale(1);
   -webkit-transition: all 0.15s ease-in-out;
   -moz-transition: all 0.15s ease-in-out;
   -ms-transition: all 0.15s ease-in-out;
   -o-transition: all 0.15s ease-in-out;
   transition: all 0.15s ease-in-out;
   border: 1px solid red; */
}
/* #page > .header.compact .main .center{
   transform: scale(0.5);
   margin: -10% 0;
} */

#page > .header .anchors{
   /* border: 1px solid red; */
   overflow-x: auto;
   overflow-y: hidden;
   margin: 0px -20px;
   padding: 0px 20px;
   -webkit-overflow-scrolling: touch;
}
#page > .header .anchors .scroller{
   white-space: nowrap;
}

#page > .header .logo{
   height: 46px;
   margin: 0 auto 12px;
   display: block;
}
#page > .header .title{
   font-weight: bold;
   color: #6f6f6f;
   margin-bottom: 10px;
}

#page > .header .logo{
   height: 46px;
   margin: 0 auto 12px;
   display: block;
   -webkit-transition: all 0.15s ease-in-out;
   -moz-transition: all 0.15s ease-in-out;
   -ms-transition: all 0.15s ease-in-out;
   -o-transition: all 0.15s ease-in-out;
   transition: all 0.15s ease-in-out;
   transform: scale(1);
}

#page > .header.compact .logo{
   width: 100%;
}

#page > .header .title{
   font-weight: bold;
   color: #6f6f6f;
   margin-bottom: 10px;
   -webkit-transition: all 0.15s ease-in-out;
   -moz-transition: all 0.15s ease-in-out;
   -ms-transition: all 0.15s ease-in-out;
   -o-transition: all 0.15s ease-in-out;
   transition: all 0.15s ease-in-out;

   opacity: 1;
}

#page > .header.compact .title{
   height: 0px;
   opacity: 0;
   margin: 0px;
}

#page > .header .back{
   border-radius: 40px;
   display: block;
   width: 40px;
   height: 40px;

   mask: url("/assets/icons/list.svg") no-repeat 50% 50%;
   mask-size: 70%;


   background-color: #39b54a;
   -webkit-transition: all 0.15s ease-in-out;
   -moz-transition: all 0.15s ease-in-out;
   -ms-transition: all 0.15s ease-in-out;
   -o-transition: all 0.15s ease-in-out;
   transition: all 0.15s ease-in-out;
   cursor: pointer;
}

#page > .header.compact .back{
  margin-top: 7px;
   width: 32px;
   height: 32px;
}

#page > .content{
   flex-grow: 1;
   flex-shrink: 1;
   overflow-x: hidden;
   overflow-y: auto;
   -webkit-overflow-scrolling: touch;
   line-height: 20px;
}

/*containers*/

.isDesktop #page .columns {
   width: calc(100% + 32px);
   box-sizing: border-box;
   display: flex;
   flex-direction: row;
   margin-left: -16px;
   margin-right: -16px;
}
.isDesktop #page .columns > .column{
   margin: 0px 16px;
}

.isDesktop #page .columns > .column-3{
   width: calc(100% / 4);
}
.isDesktop #page .columns > .column-4{
   width: calc(100% / 3);
}
.isDesktop #page .columns > .column-6{
   width: calc(100% / 2);
}

.isMobile .columns > .column{
   margin-bottom: 20px;
}








/*da sistemare*/



/*lightbox*/
#contact-form{
   top: 0px;
   left: 0px;
   width: 100vw;
   height: 100vh;
   background-color: #fff;
   z-index: 999;
   overflow-x: hidden;
   overflow-y: auto;
}


/*temp*/

#page .other-pages{
   padding: 15px 0px;
   height: 130px;
}
#page .other-pages .other-page {
   /* margin-right: 20px; */
   border: 2px solid #d3c4cf;
   border-radius: 8px;
   background-color: #f5f5f5;
   overflow: hidden;
   display: inline-flex;
   align-items: center;
}
#page .other-pages .other-page-cnt{
   display: inline-block;
   padding: 0px 10px;
}

#page .other-pages .other-page .image {
   background-color: #fff;
   width: 70px;
   height: 90px;
   flex-grow: 0;
   flex-shrink: 0;
   padding: 10px;
}
#page .other-pages .other-page .image img{
   width: 100%;
   height: 100%;
   object-fit: contain;
   object-position: center;
}
#page .other-pages .other-page .text {
   padding: 10px 10px 16px 10px;
   color: #63285b;
   flex-grow: 1;
   flex-shrink: 1;
   white-space: normal;
   max-width: 200px;
}

#page .other-pages .other-page.active {
   border-color: #63285b;
   background-color: #63285b;
}
#page .other-pages .other-page.active .text {
   color: #fff;
}

/*stili da far ereditare*/
