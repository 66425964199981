#list{
  position: absolute;
  top: 0px;
  left: 0px;

   height: 100%;
   width: 100%;
   /* border: 1px solid lime; */
}

/*desktop*/
#list.isDesktop > .content{
   position: absolute;
   top: 0px;
   left: 0px;
   width: 380px;
   height: 100%;
}
#list.isDesktop #filter{
   position: absolute;
   top: -60px;
   right: 380px;
   width: 380px;
   height: calc(100% + 60px);
}

/*mobile*/
#list.isMobile > .content{
   width: 100%;
   height: 100%;
}
#list.isMobile > #filter{
   position: absolute;
   top: -60px;
   left: 0px;
   width: 100%;
   height: calc(100% + 60px);

}

/*generic*/
#list > .content{
   overflow: hidden;
   display: flex;
   flex-direction: column;

   /* border: 1px solid orange; */
}






#list > .content > .header{
   flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 70px;
   padding: 10px 20px;
   text-align: center;
}

#list > .content > .header .main{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}

#list > .content > .header .main .left,
#list > .content > .header .main .right{
   flex-basis: 50px;
   flex-grow: 0;
   flex-shrink: 0;
}
#list > .content > .header .main .center{
   flex-grow: 0;
   flex-shrink: 1;
   text-align: center;
   /* transform: scale(1);
   -webkit-transition: all 0.15s ease-in-out;
   -moz-transition: all 0.15s ease-in-out;
   -ms-transition: all 0.15s ease-in-out;
   -o-transition: all 0.15s ease-in-out;
   transition: all 0.15s ease-in-out;
   border: 1px solid red; */
}

#list > .content > .header .logo{
   height: 46px;
   width: 100%;
   margin-bottom: 12px;
   display: inline-block;

}
#list > .content > .header .search{
   height: 46px;
   margin: 0 auto;
   display: block;
   position: relative;
   -webkit-border-radius: 3px;
   -moz-border-radius: 3px;
   border-radius: 3px;
   overflow: hidden;
}
#list > .content > .header .toggle-filters{
   position: absolute;
   top: 5px;
   right: 5px;
   width: 36px;
   height: 36px;
   /* border: 1px solid red; */
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 20px;
   cursor: pointer;

}
#list > .content > .header .toggle-filters .icon{
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   background-color: #000000;
   -webkit-mask: url("/assets/icons/filters.svg") no-repeat 50% 50%;
    mask: url("/assets/icons/filters.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}
#list > .content > .header .toggle-filters.active{
   background-color: #000000;
}
#list > .content > .header .toggle-filters.active .icon{
   background-color: #ffffff;
}

#list > .content > .header .toggle-calendar{
   position: absolute;
   top: 5px;
   right: 46px;
   width: 36px;
   height: 36px;
   /* border: 1px solid red; */
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 20px;
   z-index: 2;
}
#list > .content > .header .toggle-calendar.active{
   background-color: #63285B;
}
#list > .content > .header .toggle-calendar .icon{
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   background-color: #63285B;
   -webkit-mask: url("/assets/icons/calendar.svg") no-repeat 50% 50%;
    mask: url("/assets/icons/calendar.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}
#list > .content > .header .toggle-calendar.active .icon{
   background-color: #ffffff;
}


#list > .content > .header .search input{
   position: absolute;
   top: 0px;
   left: 0px;
   width: calc(100% - 46px);
   height: 100%;
   border: 0px;
   line-height: 46px;
   padding: 0px 20px;
   font-size: 16px;
   background-color: transparent;
}
#list > .content > .header .search input:focus{
   outline: none;
}
#list > .content > .header .search input::-webkit-input-placeholder {
   opacity: 0.5;
}
#list > .content > .header .search input::-moz-placeholder {
   opacity: 0.5;
}
#list > .content > .header .search input:-ms-input-placeholder {
   opacity: 0.5;
}
#list > .content > .header .search input:-moz-placeholder {
   opacity: 0.5;
}

#list > .content > .header .calendar{
   height: 0px;
   overflow: hidden;
   -webkit-transition: height 0.25s ease-in-out;
   -moz-transition: height 0.25s ease-in-out;
   -ms-transition: height 0.25s ease-in-out;
   -o-transition: height 0.25s ease-in-out;
   transition: height 0.25s ease-in-out;

}
#list > .content > .header .calendar.active{
   height: 250px;
   overflow: visible;
}

#list .layout-switch{
   position: relative;
   display: inline-block;
   width: 50px;
   height: 30px;
   margin-top: 8px;
}
#list .layout-switch .slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #ededed;
   -webkit-transition: .4s;
   transition: .4s;
   border-radius: 34px;
}
#list .layout-switch .slider:before {
   position: absolute;
   content: "";
   height: 30px;
   width: 30px;
   left: 0px;
   bottom: 0px;
   background-color: #CCC;
   -webkit-transition: .4s;
   transition: .4s;
   border-radius: 50%;
   background-image: url("/assets/icons/marker.svg");
   background-position: center;
   background-repeat: no-repeat;
   background-size: 50%;
}
#list .layout-switch.active .slider {
   background-color: #ededed;
}
#list .layout-switch.active .slider:before {
   -webkit-transform: translateX(20px);
   -ms-transform: translateX(20px);
   transform: translateX(20px);
   background-color: #63285B;
}




#list > .content > .content{
   flex-grow: 1;
   flex-shrink: 1;
   overflow: hidden;
   position: relative;
}
