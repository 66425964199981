#resultslist{
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   overflow: auto;
   /* -webkit-overflow-scrolling: touch; */
   /* height: 300px; */
}

#resultslist .scroller{

}

#resultslist .result{
   display: block;
   padding: 15px 20px;
   text-decoration: none;
   color: inherit;
}

#resultslist .result{
   display: flex;
}

/* #resultslist .result .left:empty,
#resultslist .result .right:empty{
	flex-basis: 10px;
	flex-shrink: 0;
   flex-grow: 0;
   border: 1px solid red;
} */
#resultslist .result .left{
	flex-basis: 30px;
	flex-shrink: 0;
   flex-grow: 0;
   margin-right: 14px;
   overflow: hidden;
}
#resultslist .result .center{
	flex-shrink: 9;
   flex-grow: 9;
}
#resultslist .result .right{
	flex-basis: 30px;
	flex-shrink: 0;
   flex-grow: 1;
   margin-left: 14px;
}
#resultslist .result .left:empty,
#resultslist .result .right:empty{
   flex-basis: 0px;
   margin: 0px;
}

#resultslist .loading{
   text-align: center;
   font-size: 16px;
   text-transform: uppercase;
   line-height: 34px;
}
#resultslist .loading i{
   width: 31px;
   height: 31px;
   font-size: 30px !important;
   padding: 30px;
   display: table !important;
   margin: auto;
   color: #63285B;
}
#resultslist .ended{
   text-align: center;
   padding: 12px 0px 12px;
   font-size: 12px;
   letter-spacing: 1px;
   text-transform: uppercase;
   line-height: 12px;
   color: #000;
}
