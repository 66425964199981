#wine-sheet{

}



#wine-sheet > .cover {
   width: 100%;
   height: 270px;
   margin-bottom: 15px;
}
#wine-sheet > .cover img {
   width: 100%;
   height: 100%;
   object-fit: contain;
}



#wine-sheet .company,
#wine-sheet .intro {
   background-color: #f5f5f5;
   text-align: center;
   padding: 20px;
}

#wine-sheet .company{
   border-bottom: 1px solid #d3c4cf;
}
#wine-sheet .company h2 {
   color: #000;
   margin: 0px 0px 0px;
}

#wine-sheet .intro h1,
#wine-sheet .intro h3,
#wine-sheet .intro p {
   margin: 0px 0px 4px;
}
#wine-sheet .intro p{
   color: #a684a1;
}

#wine-sheet .intro ul {
   margin: 30px 0px 0px;
   padding: 0px;
}
#wine-sheet .intro ul li {
   margin: 0px;
   padding: 0px;
   list-style-type: none;
   display: inline-block;
   margin-left: 5px;
   margin-right: 5px;
}
#wine-sheet .intro ul + h2 {
   margin-top: 15px;
}
#wine-sheet .intro ul li:first-child {
   margin-left: 0px;
}
#wine-sheet .intro ul li:last-child {
   margin-right: 0px;
}




#wine-sheet .band{
}
#wine-sheet .band > .title{
   background-color: #f5f5f5;
   margin-top: 10px;
   padding: 20px 20px;
   color: #63285B;
   font-size: 18px;
}
#wine-sheet .band > .content{
   background-color: #f5f5f5;
   padding: 10px 20px;
}
#wine-sheet .band.iconic > .content{
}


#wine-sheet .iconic .element{
   padding: 8px 0px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: baseline;
   border-bottom: 1px solid #d3c4cf;
}
#wine-sheet .iconic .element:last-child {
   border-bottom: 0px none #d3c4cf;
}

#wine-sheet .iconic .element .icon {
   flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 40px;
   margin-right: 15px;
}
#wine-sheet .iconic .element .icon img {
   vertical-align: middle;
   width: 100%;
   height: 100%;
   object-fit: contain;
}
#wine-sheet .iconic .element .icon.icon-border {
   border: 2px solid #63285B;
   width: 40px;
   height: 40px;
}
#wine-sheet .iconic .element .icon .icon-color {
   display: inline-block;
   vertical-align: middle;
   border: 2px solid #63285B;
   width: 40px;
   height: 40px;
}
#wine-sheet .iconic .element .name {
   flex-grow: 1;
   flex-shrink: 1;
   font-weight: 600;
   color: #63285b;
   font-size: 16px;
}
#wine-sheet .iconic .element .value {
   flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 30%;
   font-weight: 600;
   color: #6f6f6f;
   text-align: right;
}
#wine-sheet .iconic .element .value small{
   display: block;
   font-weight: 400;
}
