#sheetGallery {

   .section-content {
      .gallery-element {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         cursor: pointer;
         .gallery-image {
            object-fit: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; }
         .gallery-description {
            position: absolute;
            bottom: -100%;
            left: 0px;
            width: 100%;
            height: auto;
            padding: 14px 20px;

            background-color: rgba(0,0,0,0.8);
            color: #fff;
            transition: all 0.25s ease-in-out; } }

      .gallery-element:hover {
         .gallery-description {
            bottom: 0; } } } }
