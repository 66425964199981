#areatabs{
   display: flex;
   padding: 0px 20px;
   height: 60px;
}
#areatabs .area{
   display: block;
   flex-grow: 1;
   flex-shrink: 0;
   /* flex-basis: 33%; */
   text-align: center;
   opacity: 0.3;
   text-decoration: none;
}
#areatabs .area.active{
   opacity: 1;
}
#areatabs .area .icon{
   margin: 8px 0px 5px;
   height: 22px;
}
#areatabs .area .icon img{
   height: 100%;
}
#areatabs .area .label{
   color: inherit;
   font-weight: 600;
   font-size: 12px;
   letter-spacing: 0.2px;
}
