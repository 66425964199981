.flowershelf{
   overflow: hidden;
}

.flowershelf.loading{
	visibility: hidden;
	height: 1px;
}

.flowershelf > div{
overflow: hidden;
	display: flex;
	flex-wrap: wrap;
}

.flowershelf > div > div{
	white-space: normal;
	position: relative;
	background-color: #f1f1f1;
}

.flowershelf > div > div > img{
	width: 100%;
	height: 100%;
	border: 0px;
	opacity: 0;
	-webkit-transition: opacity 0.30s ease-in-out;
	-moz-transition: opacity 0.30s ease-in-out;
	-ms-transition: opacity 0.30s ease-in-out;
	-o-transition: opacity 0.30s ease-in-out;
	transition: opacity 0.30s ease-in-out;
}

.flowershelf > div > div.loaded img{
	opacity: 1;
}
