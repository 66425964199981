#filterCalendar .item-leaf{
   width: 100%;
}
#filterCalendar .item-leaf .text{
   flex-grow: 1;
   flex-shrink: 1;
}
#filterCalendar .item-leaf .checked{
   height: 20px;
   flex-basis: 30px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-right: 10px;
}
#filterCalendar .item-leaf.active .checked{
   background-image: url("/assets/icons/tick.svg");
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center right;
}






#filterCalendar .react-calendar{
   border: 0px;
   width: 100%;
   background-color: transparent;
   padding: 0px 15px;
}
#filterCalendar .react-calendar__month-view__days__day,
#filterCalendar .react-calendar__month-view__days__day--weekend,
#filterCalendar .react-calendar__month-view__days__day--neighboringMonth{
   color: #ffffff;
}
#filterCalendar .react-calendar__tile{
   /* opacity: 0.4; */
}
#filterCalendar .react-calendar__tile.react-calendar__tile--active{
   /* opacity: 1; */
}
#filterCalendar button.react-calendar__tile:enabled:hover,
#filterCalendar button.react-calendar__tile:enabled:focus,
#filterCalendar .react-calendar__navigation button:enabled:hover,
#filterCalendar .react-calendar__navigation button:enabled:focus{
   background-color: transparent;
}
#filterCalendar .react-calendar__tile--active,
#filterCalendar .react-calendar__tile.active{
   background-color: #ededed !important;
   color: #000;
}

#filterCalendar .react-calendar__tile{
   position: relative;
   overflow: visible !important;
}
#filterCalendar .react-calendar__tile .count{
   position: absolute;
   bottom: 0px;
   right: -4px;
   width: 20px;
   height: 20px;
   border-radius: 18px;
   text-align: center;
   font-size: 12px;
   /* background-color: #63285B; */
   background-color: #ffffffDD;
   /* color: #fff; */
   color: #63285B;
   padding-top: 1px;
   font-weight: bold;
   border: 1px solid #fff;
   -webkit-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.15);
   -moz-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.15);
   box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.15);
}

#filterCalendar .react-calendar__navigation{
   margin-bottom: 0px;
}
#filterCalendar .react-calendar__navigation__label{
   font-size: 18px;
   color: #fff;
}
#filterCalendar .react-calendar__navigation__arrow{
   font-size: 23px;
   color: #fff;
}
#filterCalendar .react-calendar__navigation__next2-button,
#filterCalendar .react-calendar__navigation__prev2-button{
   display: none;
}
