#sheetContacts {
   .address {
      margin-bottom: 20px; }
   .geographic-region {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 20px; }
   .geographic-map-trigger {
      margin-bottom: 20px; }
   .geographic-map-container,
   .hall-map-container {
      position: relative;
      max-height: 0px;
      max-width: 330px;
      float: right;
      overflow: hidden;
      -webkit-transition: all 500ms;
      -o-transition: all 500ms;
      transition: all 500ms;
      .level {
         position: absolute;
         top: 0px;
         left: 0px;
         width: 100%; } }
   .geographic-map-container.visible,
   .hall-map-container.visible {
      max-height: 600px;
      -webkit-transition: all 1000ms;
      -o-transition: all 1000ms;
      transition: all 1000ms; }
   .halls {
      font-weight: bold;
      margin-bottom: 10px;
      margin-right: 5px;
      text-align: right;
      font-size: 22px; }
   .halls.availablesoon {
      text-transform: uppercase;
      font-size: 13px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      border-radius: 30px;
      width: 90%;
      padding: 4px 15px 5px;
      text-align: center; }
   .refer {
      padding-bottom: 8px;
      margin-bottom: 8px;
      .name {
         font-weight: bold;
         margin-bottom: 3px; }
      .value {} } }

.skin-modern {
   #sheetContacts {
      .refer {
         border-bottom: 1px solid #000000; } } }

.skin-classic {
   #sheetContacts {
      .sharebuttons {
         margin-left: -15px;
         margin-right: -15px;
         margin-bottom: -15px;
         border-top: 1px solid #f1f1f1; } } }

.isMobile {
   #sheetContacts {} }
