#event-sheet{
   padding: 0px 0px 20px;
}

#event-sheet > .cover{
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 22px;
}
#event-sheet > .cover img {
  width: 100%;
  height: auto;
}
#event-sheet .icons {
  margin-top: 20px;
  padding: 0px 20px 0px 20px;
  margin-bottom: 20px;
}
#event-sheet > .cover:not(:empty) + .icons {
  position: relative;
  margin-top: -42px;
  z-index: 2;
}
#event-sheet .icons ul {
  padding: 0px;
  margin: 0px;
}
#event-sheet .icons ul li {
  display: block;
  margin-right: 7px;
}
#event-sheet .icons ul li:last-child {
  margin-right: 0px;
}
#event-sheet .icons ul li .icon {
   display: inline-block;
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background-repeat: no-repeat;
   background-size: 65%;
   background-position: center;
}

#event-sheet .icons ul li.typology .icon{
   background-size: 100%;
}
#event-sheet .icons ul li.access .icon{
   background-color: #ededed;
}

#event-sheet .icons ul li a img {
  box-shadow: 3px 0px 0px rgba(0, 0, 0, 0.75);
}

#event-sheet .icons ul li .text {
   background-color: #ededed;
   padding: 6px 15px;
   border-radius: 15px;
   text-transform: uppercase;
   font-size: 12px;
   position: relative;
   top: -16px;
   margin-left: 10px;

}

#event-sheet > .content {
  padding: 0px 20px 0px 20px;
}
#event-sheet > .content .info{
   margin-bottom: 35px;
}

#event-sheet > .content .info .day{
   margin-top: 20px;
}

#event-sheet > .content .info .day .date{
   color: #63285b;
   font-weight: 600;
   font-size: 22px;
   margin-bottom: 4px;
   letter-spacing: 0.8px;
}
#event-sheet > .content .info .day .time{
   font-weight: 600;
   font-size: 18px;
   color: #a684a1;
   margin-bottom: 4px;
}

#event-sheet > .content .info .day .date,
#event-sheet > .content .info .day .time{
   margin: 0px 0px 4px;
}

#event-sheet > .content .description {
  margin: 5px 0px 30px;
}
#event-sheet > .content .web {
   margin-top: 10px;
   color: #63285B;
   text-decoration: none;
   word-wrap: break-word;
}
#event-sheet > .content .direct {
   margin-top: 40px;
}
#event-sheet .winery:not(:empty) {
   margin-top: 40px;
}
