#veronafiere-classic-sheet{
  padding-top: 80px;
}

#veronafiere-classic-sheet > .cover {
   width: 100%;
   height: 270px;
   margin-bottom: 15px;
}
#veronafiere-classic-sheet > .cover img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}



#veronafiere-classic-sheet .company{
   text-align: center;
   padding: 0px 20px 40px;
}

#veronafiere-classic-sheet .company .logo{
   /* width: 100%;
   height: auto; */
   margin-top: 20px;
}
#veronafiere-classic-sheet .company .logo img{
   width: 70%;
   max-height: 100px;
   object-fit: contain;
   object-position: center;
}
#veronafiere-classic-sheet .company p{
   text-align: left;
}



#veronafiere-classic-sheet .direct {
   padding: 10px 20px 0px;
}



/*menu band*/

/* #veronafiere-classic-sheet .band .menu{
   padding: 10px 0px 5px;
}
#veronafiere-classic-sheet .band .menu .item{
   display: inline-block;
   padding-bottom: 2px;
   border-bottom: 2px solid transparent;
   font-weight: bold;
   opacity: 0.5;
   color: #63285b;
}
#veronafiere-classic-sheet .band .menu .item.active{
   border-color: #63285b;
   opacity: 1;
}
#veronafiere-classic-sheet .band .menu .item:not(:last-child){
   margin-right: 20px;
} */


/*iconic*/
#veronafiere-classic-sheet .band{
}
#veronafiere-classic-sheet .band > .title{
   /* background-color: #f5f5f5; */
   margin-top: 10px;
   padding: 20px 20px;
   font-size: 18px;
}
#veronafiere-classic-sheet .band .content{
   /* background-color: #f5f5f5; */
}
#veronafiere-classic-sheet .band > .content{
   padding: 10px 20px;
}
#veronafiere-classic-sheet .iconic .element{
   padding: 8px 0px;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   align-items: baseline;
   border-bottom: 1px solid #d3c4cf;
   flex-basis: 100%;
}
#veronafiere-classic-sheet .iconic .element:last-child {
   border-bottom: 0px none #d3c4cf;
}
#veronafiere-classic-sheet .iconic .element .panelData {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;

}

#veronafiere-classic-sheet .iconic .element .icon {
   flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 40px;
   margin-right: 15px;
}
#veronafiere-classic-sheet .iconic .element .icon img {
   vertical-align: middle;
   width: 100%;
   height: 100%;
   object-fit: contain;
}
#veronafiere-classic-sheet .iconic .element .icon .icon-color {
   border: 2px solid #63285B;
   width: 40px;
   height: 40px;
}
#veronafiere-classic-sheet .iconic .element .name {
   flex-grow: 1;
   flex-shrink: 1;
   font-weight: 600;
   color: #63285b;
   font-size: 16px;
}
#veronafiere-classic-sheet .iconic .element .value {
   flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 20%;
   font-weight: 600;
   color: #6f6f6f;
   text-align: right;
}
#veronafiere-classic-sheet .iconic .element .value small{
   display: block;
   font-weight: 400;
}

/*contacts*/
#veronafiere-classic-sheet .band.contacts > .content{
}
#veronafiere-classic-sheet .contacts .element{
   padding: 10px 0px;
   border-bottom: 1px solid #d3c4cf;
}
#veronafiere-classic-sheet .contacts .element:last-child {
   border-bottom: 0px none #d3c4cf;
}

#veronafiere-classic-sheet .contacts .element .name {
   font-weight: 600;
   font-size: 16px;
   color: #000;
   margin-bottom: 6px;
}
#veronafiere-classic-sheet .contacts .element .value {
   color: #63285b;
   word-break: break-word;
}
#veronafiere-classic-sheet .contacts .element .value a:not(:last-child):after,
#veronafiere-classic-sheet .contacts .element .value span:not(:last-child):after{
   content: ", ";
}


#veronafiere-classic-sheet .social-icon{
   display: inline-block;
   width: 30px;
   height: 30px;
   margin: 5px 10px 5px 0px;
}
.isMobile #veronafiere-classic-sheet .social-icon{
   width: 50px;
   height: 50px;
   margin: 10px 20px 10px 0px;
}



/*merecologies*/
#veronafiere-classic-sheet .band.merceologies .merceology{
   padding: 15px 0px;
   background-color: #f5f5f5;
}
#veronafiere-classic-sheet .band.merceologies .merceology span:not(:last-child):after{
   content: ",";
   display: inline-block;
   padding-right: 6px;
}

/*gallery*/

#veronafiere-classic-sheet .band.gallery img{
   width: 100%;
}
#veronafiere-classic-sheet .band.gallery .videoContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* height: 0;
  padding-bottom: 56.25%; */
  margin-bottom: 2px;

}
#veronafiere-classic-sheet .band.gallery .videoContainer iframe {
  /* position: absolute; */
  /* top: 0px;
  left: 0px; */
  width: 100%;
  /* height: 100%; */
}
#veronafiere-classic-sheet .band.gallery .videoContainer .caption {
   padding: 5px 10px;
   background-color: #000000aa;
   color: #ffffff;
   position: relative;
   top: -4px;

}
#veronafiere-classic-sheet .band.gallery .imageContainer {
  position: relative;
  width: 100%;
  margin-bottom: 2px;

}
#veronafiere-classic-sheet .band.gallery .imageContainer img {

}
#veronafiere-classic-sheet .band.gallery .imageContainer .caption {
   position: absolute;
   bottom: 4px;
   left: 0px;
   width: 100%;
   height: auto;
   padding: 5px 10px;
   background-color: #000000aa;
   color: #ffffff;
}



/*more */
#veronafiere-classic-sheet .iconic .element .panelData .more{
  flex-basis: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  color: #63285b;
  opacity: 0.4;
  transition: all 500ms;
  text-align: right;
}
#veronafiere-classic-sheet .iconic .element .panelData:hover .more {
  opacity: 1;
}
#veronafiere-classic-sheet .iconic .element .hiddenPanel{
  max-height: 0px;
  overflow: hidden;
  background-color: rgba(0,0,0,0.05);
  color: #333;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  transition: all 500ms;
  transition-delay: 0s;
  margin-top: 5px;
}
#veronafiere-classic-sheet .iconic .element .hiddenPanel.visible{
  max-height: 2000px;
  transition: all 1000ms;
}
#veronafiere-classic-sheet .iconic .element .hiddenPanel .containerHiddenPanel{
    padding: 30px 55px;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
}
#veronafiere-classic-sheet .iconic .element .hiddenPanel .containerHiddenPanel h4 {
  color: #63285B;
  margin: 0px 0px 20px 0px;
  font-size: 20px;
  font-weight: normal;
}
#veronafiere-classic-sheet .iconic .element .hiddenPanel .containerHiddenPanel div:nth-child(n+2) h4 {
  margin-top:50px;
}
#veronafiere-classic-sheet .iconic .element .hiddenPanel .containerHiddenPanel p img {
  vertical-align: text-bottom;
  margin-right: 5px;
  height: 30px;
  /* position: relative;
  bottom:  */
  margin-bottom: -7px;
}
/* bottone padiglione e regione */

 #veronafiere-classic-sheet .company .panel {
  margin-top:10px;
  text-align: left;
}
#veronafiere-classic-sheet .company .panel a{
  text-transform: capitalize;
    text-align: left;
    padding-left: 10px;
    position: relative;
}
#veronafiere-classic-sheet .company .panel a i {
  position: absolute;
  width: 17px;
  height: 14px;
  top: calc(50% - 7px);
  right: 10px;
  background-image: url('/assets/icons/next.svg' );
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
 #veronafiere-classic-sheet .company .panel .mappa {
   position: relative;
   max-height: 0px;
   overflow: hidden;
   transition: all 500ms;
 }
  #veronafiere-classic-sheet .company .panel .mappa.visible {
    max-height: 600px;
    transition: all 1000ms;
  }
 #veronafiere-classic-sheet .company .panel .mappa img {
   margin-top:10px;
 }
 #veronafiere-classic-sheet .company .panel .mappa img:nth-child(2){
   position: absolute;
   top: 0px;
   left: 0px;
 }
 .slick-dots {
   bottom: -30px;
 }
.slick-dots li button:before {
   color:#63285B !important;
 }


#filter [data-group="ALTRO"] .item:nth-child(9),
#filter [data-group="ALTRO"] .item:nth-child(10),
#filter [data-group="ALTRO"] .item:nth-child(11){
   width: 100%;
}



/* #veronafiere-classic-sheet .slick-slider{
   margin-top: 40px;
}
#veronafiere-classic-sheet .slick-next,
#veronafiere-classic-sheet .slick-prev {
   top: -30px;
   width: 30px;
   height: 30px;
}
#veronafiere-classic-sheet .slick-next{
   right: auto;
   left: calc(100vw - 60px);
   background-image: url("/assets/icons/slick-next.svg") !important;
}
#veronafiere-classic-sheet .slick-prev {
   left: 20px;
   background-image: url("/assets/icons/slick-prev.svg") !important;
}
#veronafiere-classic-sheet .slick-prev:before,
#veronafiere-classic-sheet .slick-next:before{
		content: "" !important;
		display: none !important;
} */
