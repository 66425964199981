#filter{
   /* position: absolute;
   top: 0px;
   left: 0px;
   width: 100%; */
   z-index: 9999;

   -webkit-box-shadow: inset 0px -2px 2px 1px rgba(0,0,0,0.1);
   box-shadow: inset 0px -2px 2px 1px rgba(0,0,0,0.1);

}
#filter > .panel{
   width: 100%;
   height: 100%;
   position: relative;
}


/*header*/
#filter > .panel > .header{
   /* flex-grow: 0;
   flex-shrink: 0; */
   padding: 12px 20px;
   height: 68px;
}

#filter > .panel > .header > .main{
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}

#filter > .panel > .header > .main > .left,
#filter > .panel > .header > .main > .right{
   flex-basis: 50px;
   flex-grow: 0;
   flex-shrink: 0;
}
#filter > .panel > .header > .main > .center{
   flex-grow: 0;
   flex-shrink: 1;
   text-align: center;
   text-transform: uppercase;
   font-size: 17px;
   font-weight: 400;
   letter-spacing: 1px;
}

#filter > .panel > .header > .main > .right{
   text-align: right;
}
#filter > .panel > .header > .main > .right > *{
   float: right;
}

#filter > .panel > .content{
   flex-grow: 1;
   flex-shrink: 1;
   position: relative;
   overflow: hidden;
   height: calc(100% - 163px);
}
#filter > .panel > .footer{
   height: 95px;
   padding: 20px;
}


#filter .back{
   border-radius: 40px;
   display: block;
   margin-top: 5px;
   width: 30px;
   height: 30px;
   background-color: #ffffff;
   /*background-image: url("/assets/icons/arrow-left-1.svg");
   background-repeat: no-repeat;
   background-size: 50%;
   background-position: center;
*/
   mask: url("/assets/icons/arrow-left-1.svg") 50% 50% / contain no-repeat;
   mask-size: contain;
   -webkit-transition: all 0.15s ease-in-out;
   -moz-transition: all 0.15s ease-in-out;
   -ms-transition: all 0.15s ease-in-out;
   -o-transition: all 0.15s ease-in-out;
   transition: all 0.15s ease-in-out;
   cursor: pointer;
}

#filter .clear-filters{
   display: inline-block;
   margin-top: 4px;
   width: 30px;
   height: 30px;
   z-index: 9;


   border-radius: 5px; /*40px round*/
   background-color: #ffffff;
   /*background-image: url("/assets/icons/trash-1.svg");
   background-position: center;
   background-repeat: no-repeat;*/

  mask: url("/assets/icons/trash-1.svg") 50% 50% / contain no-repeat;
  mask-size: contain;
   background-size: 70%;
   cursor: pointer;
}

#filter .close-filters{
   display: inline-block;
   width: 40px;
   height: 40px;
   z-index: 9;

}


/*items*/
/*
#filter .layout{
   display: flex;
   flex-direction: column;
}
#filter .search-container{
   flex-shrink: 0;
   flex-grow: 0;
}
#filter .items-scroller{
   flex-shrink: 1;
   flex-grow: 1;

   position: relative;
}
#filter .items{

   position: absolute;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   overflow-x: hidden;
   overflow-y: auto;

   -webkit-overflow-scrolling: touch;

} */


#filter .filter-wrapper,
#filter .filter-subwrapper{
   position: relative;
   height: 100%;

}
#filter .search-container{
   height: 76px;
}
#filter .items-container{
   /* border: 3px solid lime; */
   /* height: calc(100% - 76px); */
   height: 100%;
   position: relative;
   /* overflow: scroll; */
   overflow-x: hidden;
   overflow-y: auto;
   -webkit-overflow-scrolling: touch;
   border-top: 1px solid #1A1A1A55;
}
#filter .search-container + .items-container{
   height: calc(100% - 76px);

}
#filter .items-container .items-scroller{
   /* border: 3px solid yellow; */

}


/*item*/
#filter .item-wrapper{
   position: relative;
   padding: 20px 20px;
   border-bottom: 1px solid #1A1A1A55;
   cursor: pointer;
}
#filter .item-subwrapper{
   width: 100%;
}
#filter .item-wrapper .item{
   width: 100%;
   display: flex;
   align-items: center;
}
#filter .item-wrapper .item .text{

   flex-grow: 1;
   flex-shrink: 1;
   padding-right: 20px;
   letter-spacing: 1.5px;
   font-size: 16px;
}
#filter .item-wrapper .item .icon{
   flex-grow: 0;
   flex-shrink: 0;
   width: 20px;
   height: 20px;

}

#filter .item .icon{
   background-image: url("/assets/icons/next.svg");
   background-size: contain;
   background-repeat: no-repeat;
   background-position: top right;
}




/*footer*/
#filter > .panel > .footer .btn:not(:last-child){
   margin-bottom: 10px;
}

#filter > .panel > .footer .btn .counter{
   display: inline-block;
   position: relative;
   background-color: #63285B;
   min-width: 20px;
   height: 20px;
   margin-left: 10px;
   color: #fff;
   border-radius: 10px;
   top: -2px;
   padding: 2px 2px 0px 2px;
   text-align: center;
   font-size: 14px;
}

#filter > .panel .footer .btn-100{
   padding: 8px 0px;
   border-radius: 5px;
}

#filter > .panel .text{
   font-weight: 400;
}
#filter > .panel .counters{
   font-size: 12px;
}


.item-leaf .counter{
   right: 20px;
   flex-grow: 0;
   flex-shrink: 0;
   text-align: center;
   background-color: #00000033;
   border-radius: 20px;
   font-size: 12px;
   padding: 5px 10px;
   min-width: 30px;
   letter-spacing: 1px;
   /* color: #63285B; */
}

#filter .search{
   width: calc(100% - 40px);
   margin: 20px 20px 10px;
   border: 0px;
   line-height: 46px;
   padding: 0px;
   font-size: 16px;
   background-color: transparent;
   border-bottom: 2px solid #ffffff;
   border-radius: 0px;
   height: 46px;
   color: #ffffff;
}
