#sheetProductsList {}


.skin-modern {
   #sheetProductsList {
      // .section-content
      //    padding-left: 7.5px
      //    padding-right: 7.5px
      //    .thumb
 } }      //       margin: 0px 7.5px


.skin-classic {
   #sheetProductsList {
      .section-content {
         padding: 0px;
         .thumb {
            margin: 0px;
            margin-right: 1px; } } } }

// #company-sheet .band.slides > .content{
//    padding: 20px 0px 30px 0px;
// }
// #company-sheet  .slider-cnt{
//    width: 80%;
//    /* border: 1px solid red; */
//    overflow: visible;
// }
// #company-sheet  .slider-cnt .slick-slider,
// #company-sheet  .slider-cnt .slick-list{
//    overflow: visible;
// }
// #company-sheet .slick-dots{
//    width: 100vw;
//
// }
//
// #company-sheet .band.slides > .content .wine-thumb,
// #company-sheet .band.slides > .content .event-thumb{
//    margin: 0px 20px;
// }
