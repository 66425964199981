.event-thumb {
   overflow: hidden;
   background-color: #fff;


   .cover {
      height: 200px;
      position: relative;

      img {
         object-fit: cover; } }

   .content {
      text-align: left;
      padding: 15px;
      background-color: #000000;
      color: #fff;
      height: 100px;
      .title {
         margin: 0px 0px 4px;
         color: #fff;
         font-size: 16px; }
      .date {
         margin: 0px 0px 4px;
         color: #fff;
         font-size: 12px; } } }

.skin-modern {
   .event-thumb {
      border: 1px solid #c9c9c9;
      border-radius: 10px;
      box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.2);

      .cover {
         border-bottom: 1px solid #c9c9c9;

         img {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            margin: 10px; } } } }


.skin-classic {
   .event-thumb {

      .cover {

         img {
            width: 100%;
            height: 100%; } } } }



// #company-sheet .slick-next,
// #company-sheet .slick-prev {
//    top: -30px;
//    width: 30px;
//    height: 30px;
// }
// #company-sheet .slick-next{
//    right: auto;
//    left: calc(100vw - 60px);
//    background-image: url("/assets/icons/slick-next.svg") !important;
// }
// #company-sheet .slick-prev {
//    left: 20px;
//    background-image: url("/assets/icons/slick-prev.svg") !important;
// }
// #company-sheet .slick-prev:before,
// #company-sheet .slick-next:before{
// 		content: "" !important;
// 		display: none !important;
// }


//
// .event-thumb{
//    border-radius: 10px;
//    border: 1px solid #c9c9c9;
//    -webkit-box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.2);
//    -moz-box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.2);
//    box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.2);
//    overflow: hidden;
//    background-color: #fff;
// }
// .event-thumb > .cover{
//    height: 200px;
//    border-bottom: 1px solid #c9c9c9;
//    position: relative;
// }
// .event-thumb > .cover img{
//    width: 100%;
//    height: 100%;
//    object-fit: cover;
// }
//
// .event-thumb > .cover:empty{
//    display: none;
// }
//
//
// .event-thumb .icons {
//   margin-top: 20px;
//   padding: 0px 20px 0px 20px;
//   margin-bottom: 20px;
// }
// .event-thumb > .cover:not(:empty) + .icons {
//   position: relative;
//   margin-top: -22px;
//   z-index: 2;
// }
// .event-thumb .icons ul {
//   padding: 0px;
//   margin: 0px;
// }
// .event-thumb .icons ul li {
//   display: inline-block;
//   margin-right: 7px;
// }
// .event-thumb .icons ul li:last-child {
//   margin-right: 0px;
// }
// .event-thumb .icons ul li .icon {
//    display: inline-block;
//    width: 40px;
//    height: 40px;
//    border-radius: 50%;
//    background-repeat: no-repeat;
//    background-size: 65%;
//    background-position: center;
// }
//
// .event-thumb .icons ul li.typology .icon{
//    background-size: 100%;
// }
// .event-thumb .icons ul li.access .icon{
//    background-color: #ededed;
// }
//
// .event-thumb .icons ul li a img {
//   box-shadow: 3px 0px 0px rgba(0, 0, 0, 0.75);
// }
//
// .event-thumb > .content{
//    margin: 15px;
//    text-align: center;
// }
//
//
// .event-thumb .info{
//    margin-bottom: 35px;
// }
// .event-thumb .info h1,
// .event-thumb .info h2,
// .event-thumb .info h3{
//    margin: 0px 0px 4px;
// }
//
// .event-thumb .btn{
//    margin-top: 30px;
// }
