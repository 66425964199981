.product-thumb {
   overflow: hidden;
   background-color: #fff;


   .cover {
      height: 200px;
      position: relative;

      img {
         object-fit: contain; } }

   .content {
      text-align: left;
      padding: 15px;
      background-color: #000000;
      color: #fff;
      height: 100px;
      h1 {
         margin: 0px 0px 4px;
         color: #fff;
         font-size: 16px; } } }

.skin-modern {
   .product-thumb {
      border: 1px solid #c9c9c9;
      border-radius: 10px;
      box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.2);

      .cover {
         border-bottom: 1px solid #c9c9c9;

         img {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            margin: 10px; } } } }


.skin-classic {
   .product-thumb {

      .cover {

         img {
            width: 100%;
            height: 100%; } } } }



// #company-sheet .slick-next,
// #company-sheet .slick-prev {
//    top: -30px;
//    width: 30px;
//    height: 30px;
// }
// #company-sheet .slick-next{
//    right: auto;
//    left: calc(100vw - 60px);
//    background-image: url("/assets/icons/slick-next.svg") !important;
// }
// #company-sheet .slick-prev {
//    left: 20px;
//    background-image: url("/assets/icons/slick-prev.svg") !important;
// }
// #company-sheet .slick-prev:before,
// #company-sheet .slick-next:before{
// 		content: "" !important;
// 		display: none !important;
// }
