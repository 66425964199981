#sheetRelations {

   .section-content {
      .menu {
         padding: 10px 0px 5px;

         .item {
            border-bottom: 2px solid transparent;
            display: inline-block;
            padding-bottom: 2px;
            border-bottom: 2px solid transparent;
            font-weight: bold;
            opacity: 0.5;
            cursor: pointer; }

         .item.active {
            border-color: #6f6f6f;
            opacity: 1; }

         .item:not(:last-child) {
            margin-right: 20px; } }

      .relation {
         padding-top: 3px;

         span {
            display: block;
            color: #6f6f6f;
            padding-top: 12px; } } } }


.skin-modern {
   #sheetRelations {
      background-color: #6f6f6f;

      .section-content {
         .menu {
            .item {
               color: #fff; }
            .item.active {
               border-color: #fff; } }

         .relation {
            span {
               color: #ffffff; } } } } }
