#filterRange .item-leaf{
   width: 100%;
}
#filterRange .item-leaf .text{
   flex-grow: 1;
   flex-shrink: 1;
}
#filterRange .item-leaf .checked{
   height: 25px;
   flex-basis: 30px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-right: 10px;
}
#filterRange .item-leaf.active{
   background-color: #ffffff65;
}
#filterRange .item-leaf.active-start .checked{
   background-image: url("/assets/icons/range-start.svg");
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center right;
}
#filterRange .item-leaf.active-end .checked{
   background-image: url("/assets/icons/range-end.svg");
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center right;
}
#filterRange .item-leaf.active-start.active-end .checked{
   background-image: url("/assets/icons/range-single.svg");
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center right;
}
