#sheetFullCover {
   text-align: center;
   background-color: #ffffff !important;

   .cover {
      position: relative;

      .blur {
         position: absolute;
         top: 0px;
         left: 0px;
         width: 100%;
         height: 100%;
         overflow: hidden;

         .blurred-image {
            position: absolute;
            top: -30px;
            left: -30px;
            background-size: cover;
            background-position: center;
            width: calc(100% + 60px);
            height: calc(100% + 60px);
            webkit-filter: blur(30px);
            -moz-filter: blur(30px);
            -o-filter: blur(30px);
            -ms-filter: blur(30px);
            filter: blur(30px); } }

      .blur-fallback {
         position: absolute;
         top: 0px;
         left: 0px;
         width: 100%;
         height: 100%;
         z-index: -1;
         overflow: hidden;
         background-image: url('https://leolabs.imgix.net/2014/07/Lake.jpg');
         background-position: center;
         background-repeat: no-repeat;
         background-size: cover; }
      .image {
         position: relative;
         .normal-image {
            max-height: 500px;
            max-width: 100%; } } }

   .title {
      padding-top: 60px;
      padding-bottom: 70px;

      h1 {
         font-size: 27px;
         font-weight: 500;
         color: #6f6f6f; }

      h2 {
         margin: 0px 0px 30px;
         padding: 0px;
         font-size: 30px;
         font-weight: 700;
         color: #6f6f6f; }

      h3 {
         margin: 4px 0px 0px;
         padding: 0px;
         font-size: 27px;
         font-weight: 300;
         color: #6f6f6f; }

      h4 {
         margin: 4px 0px 10px;
         padding: 0px;
         font-size: 17px;
         font-weight: 700;
         color: #6f6f6f; }

      .logo {
         display: inline-block;
         max-width: 300px;
         height: 125px;
         width: 100%;
         margin-top: 30px;

         img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center; } } }



   .cover + .title {
      padding-top: 30px;
      padding-bottom: 60px; } }

.isMobile {
   #sheetFullCover {
      .cover {
         margin-bottom: 30px;

         .image img {
            max-height: 270px;
            max-width: 100%; }

         .title {
            padding-top: 30px;
            padding-bottom: 20px;

            h1 {
               font-weight: 600;
               font-size: 22px; }

            h2 {}

            h3 {}

            h4 {} } } } }
